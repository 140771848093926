import template from './merchandise.html';
import styling from './merchandise.less';

export default angular.module('eventix.shop.merchandise',[])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.shop.merchandise', {
            url: '/merchandise',
            views: {
                shop: {
                    controller: 'merchandiseController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/{
            }
        });
    })
    .directive('repaint', function($timeout) {
        return {
            restrict: 'C',
            link: function(scope, el, attrs) {
                $timeout(() => el.css({ zIndex: 4 }), 500);
            }
        };
    })
    .controller('merchandiseController', function($state, $scope, shop, order, products, tickets, LaravelValidationRules, $filter,
                                                   Rollbar, FB, $q, UIMessages, VisitorData, $analytics, OAuthTokens, MetaData, ShopAnalytics, Ticket, $rootScope, $window) {

        $rootScope.pageTitle = 'Tickets';

        /// Make sure to scroll to top
        $window.scrollTo(0, 0);

        var vm = this;
        vm.order = order;
        vm.ticketReservations = vm.order.reservations;
        vm.shop = shop;
        vm.tickets = tickets;
        vm.availableEvents = _.reduce(order.events, (total, event) => total + (event.availableTickets ? 1 : 0), 0);
        vm.fourStepWizard = vm.shop.event_selection === 'enabled' || (vm.availableEvents >= 5 && vm.shop.event_selection === 'auto');
        vm.eventId = "7c897f18-bcea-487a-9449-b44ce6f3957f";
        vm.globalProducts = [];
        order.tickets[vm.eventId].map((p) => {
            if (
                p.name.startsWith('Oranjebloesem Trui size') ||
                p.name.startsWith('Oranjebloesem T-shirt size') ||
                p.name.startsWith('Oranjebloesem buckethat size') ||
                p.name.startsWith('Oranjebloesem sjaal')
            ) {
                vm.globalProducts.push(p);
                return p;
            }
            return false;
        });

        // if(_.flatten(_.values(order.reservations)).length < 1) {
        //     $state.go('eventix.shop.events', {shopId: shop.guid});
        // }

        // if (order.hasInvalidReservations()) {
        //     $state.go('eventix.shop.lockerAndCo2', {shopId: shop.guid});
        //     return;
        // }


        // Keep track of reservations
        // vm.globalProducts = globalProducts;

        /* ------- Sweater stuff ------- */
        vm.totalSweaterProducts = 0;
        vm.sweaterProducts = _.filter(_.map(vm.globalProducts, function(gp) {
            if (gp.name.startsWith('Oranjebloesem Trui size')){
                // vm.totalSweaterProducts += vm.order.globalProductReservations.filter(g => g.product_guid === gp.guid).length

                gp.displayName =  (gp.name).replace('Oranjebloesem Trui size ', '');
                if(!vm.currentSweaterProductGuid){
                    vm.currentSweaterProductGuid = gp.guid;
                }
                return gp;
            }
            return null;
        }));

        /* ------- Tshirt stuff ------- */
        vm.totalTshirtProducts = 0;
        vm.tShirtProducts = _.filter(_.map(vm.globalProducts, function(gp) {
            if (gp.name.startsWith('Oranjebloesem T-shirt size')){
                // vm.totalTshirtProducts += vm.order.globalProductReservations.filter(g => g.product_guid === gp.guid).length

                gp.displayName =  (gp.name).replace('Oranjebloesem T-shirt size ', '');
                if(!vm.currentTshirtProductGuid){
                    vm.currentTshirtProductGuid = gp.guid;
                }
                return gp;
            }
            return null;
        }));

        /* ------- Buckethat stuff ------- */
        vm.totalHatProducts = 0;
        vm.hatProducts = _.filter(_.map(vm.globalProducts, function(gp) {
            if (gp.name.startsWith('Oranjebloesem buckethat size')){
                // vm.totalHatProducts += vm.order.globalProductReservations.filter(g => g.product_guid === gp.guid).length

                gp.displayName =  (gp.name).replace('Oranjebloesem buckethat size ', '');
                if(!vm.currentHatProductGuid){
                    vm.currentHatProductGuid = gp.guid;
                }
                return gp;
            }
            return null;
        }));

        /* ------- Shawl stuff ------- */
        vm.totalShawlProducts = 0;
        vm.shawlProduct = _.find(vm.globalProducts, function(gp){
            if(gp.name.startsWith('Oranjebloesem sjaal')){
                // vm.totalShawlProducts += vm.order.globalProductReservations.filter(g => g.product_guid === gp.guid).length
                return true;
            }
        });

        console.log(vm.shawlProduct);

        // vm.sendProductGuids = ['1622eb68-7fbd-4945-892e-b4202129b571', 'a3aad8d5-8458-459f-af59-959a8166b855'];
        // vm.sendProducts = [];
        // vm.sendProducts = _.filter(vm.globalProducts, function(gp){
        //     if(vm.sendProductGuids.includes(gp.guid)){
        //         return true;
        //     }
        //     return false;
        // });

        vm.back = () => {
            return $state.go('eventix.shop.lockerAndCo2', { shopId: shop.guid });
        };
        vm.next = () => {
            let invalid = order.hasInvalidReservations();
            if(!invalid) {
                $state.go('eventix.shop.personalDetails', {shopId: shop.guid});
            }
            if(angular.isString(invalid)){
                UIMessages.push(invalid);
                return false; // stay on same reservation with error handling
            }
        };
    }).component('customProductReservationForm', {
        template: `
<span class="span-wraper">
    <button class="md_remove-icon" ng-click="$ctrl.release()" type="button"  ng-disabled="$ctrl.displayCount < 1 || $ctrl.busy"></button>
    <span class="md_ticket-count">
        {{ $ctrl.displayCount }}
    </span>
    <button class="md_add-icon" ng-click="$ctrl.reserve()" type="button" ng-disabled="$ctrl.busy"></button>
</span>`,
        bindings: { product: '<', order: '<', totalCount: '=' },
        controller: function($http, $scope, $rootScope, GlobalProductReservation, $q, syncHttp, $filter, Product, $translate,UIMessages) {
            const $ctrl = this

            $ctrl.busy = false
            $ctrl.displayPice = 0;
            $ctrl.displayCount = 0;
            $ctrl.totalCount = $ctrl.totalCount; // for some reason this is needed

            $ctrl.reserve = reserve;

            $ctrl.release = release;

            $ctrl.isNowChecked = false;
            $ctrl.isInit = true;

            $ctrl.$postLink = () => {
                $ctrl.shop = $ctrl.order.shop
                // $ctrl.displayCount = $ctrl.order.globalProductReservations.filter(g => g.product_guid === $ctrl.product.guid).length
            };


            /**
             * Reserve a global product
             */
            function reserve() {
                $ctrl.busy = true;
                $ctrl.order.reserveTicket($ctrl.product.guid, "7c897f18-bcea-487a-9449-b44ce6f3957f");
                //     .then((result) => {
                //     console.log(result)
                //     $ctrl.displayCount++;
                //     $ctrl.totalCount++;
                //     $ctrl.busy = false;
                // });

                // let productId = $ctrl.product.guid;
                //
                //
                // return $http.get(`/${$ctrl.shop.guid}/reserve/product/${productId}`)
                //     .then((response) => {
                //         response.data = Object.assign(response.data, {
                //             product_guid: productId
                //         })
                //
                //         let productReservation =  new GlobalProductReservation(response.data, $ctrl.shop)
                //         $ctrl.order.globalProductReservations.push(productReservation)
                //         $rootScope.$broadcast('GlobalProductReservationsChanged')
                //
                //         // updateMetaData()
                //         // ticket.constructor.broadcastAPIMessage(response)
                //         $ctrl.busy = false;
                //         return response.data.reservation
                //     }, error => {
                //         $ctrl.busy = false;
                //         UIMessages.push((error.data.error_description ? error.data.error_description : 'Error'));
                //         return;
                //     })
            }

            /**
             * Release a global product reservation
             * @param {boolean} sync whether or not to call this function synchronized
             */
            function release(sync) {
                $ctrl.busy = true;
                $ctrl.order.releaseTicket($ctrl.product.guid, "7c897f18-bcea-487a-9449-b44ce6f3957f");
                // .then(result => {
                //
                //     console.log(result);
                //     $ctrl.busy = false;
                //     $ctrl.displayCount--;
                //     $ctrl.totalCount--;
                // });

                // const productId = $ctrl.product.guid
                // let globalProductReservationsForProduct = $ctrl.order.globalProductReservations.filter(g => g.product_guid === productId)
                // console.log(globalProductReservationsForProduct);
                //
                // $ctrl.busy = true
                //
                // if (globalProductReservationsForProduct.length > 0) {
                //     const guid = globalProductReservationsForProduct[[globalProductReservationsForProduct.length-1]].reservation
                //     const url = `/${$ctrl.shop.guid}/reserve/product/${productId}/${guid}`
                //     let promise = null
                //
                //     if (sync) {
                //         promise = syncHttp('DELETE', url)
                //     }
                //     else {
                //         promise = $http.delete(url)
                //     }
                //
                //     return promise
                //         .then(response => {
                //             $ctrl.order.globalProductReservations = $ctrl.order.globalProductReservations.filter(g => g.reservation !== guid)
                //             $rootScope.$broadcast('GlobalProductReservationsChanged')
                //             $ctrl.busy = false;
                //             $ctrl.displayCount--;
                //             $ctrl.totalCount--;
                //             // updateMetaData()
                //             return response.data
                //         }, error => {
                //             $ctrl.busy = false
                //
                //             return $q.reject(error)
                //         })
                // }
                // else {
                //     return new Promise((resolve, reject) => {
                //         $ctrl.busy = false
                //
                //         reject('No global products reserved with this product guid.')
                //     })
                // }
            }

            $ctrl.getLabel = function(meta, productId, index) {
                let product = Product.cached[productId]
                let productName = product.name;
                let label = `${index + 1}. ${productName}: `;
                if(meta.type === 'boolean')
                    return label;
                label += $translate.instant(meta.translateName);
                return label;
            };
        }
    }).name;
// .component('customVerzendReservationForm', {
//         template: `<div>
//     <div class="md_radio-buttons" ng-repeat="product in $ctrl.sendProducts">
//         <div>
//             <input type="radio" id="{{ product.guid }}" name="radio-group" ng-model="$ctrl.currentProductGuid" ng-value="product.guid" ng-checked="$ctrl.currentProductGuid === product.guid">
//             <label for="{{ product.guid }}"><custom-translate>{{ product.name }}</custom-translate></label>
//         </div>
//     </div>
// </div>
// <custom-metadata-collection ng-model="$ctrl.order.globalProductsProductValues[reservation.reservation]"
//                                  class="row"
//                                  metadata="$ctrl.order.globalProductMetadatas[$ctrl.currentProductGuid]"
//                                  ng-if="$ctrl.order.globalProductMetadatas[$ctrl.currentProductGuid].length"
//                                  errors="$ctrl.productErrors[reservation.reservation]">
//                 <form-el ng-repeat="meta in $ctrl.order.globalProductMetadatas[$ctrl.currentProductGuid]"
//                          errors="$ctrl.order.globalProductErrors[reservation.reservation][meta.guid]"
//                          ng-attr-required="{{meta.extra | includes:'required'}}"
//                          label="{{$ctrl.getLabel(meta, $ctrl.currentProductGuid, $parent.$index)}}"
//                          input-width="9" no-help>
//                     <custom-metadata-edit ng-model="$ctrl.order.globalProductsProductValues[reservation.reservation][meta.guid]"
//                                    ng-change="$ctrl.notify(meta, reservation)"
//                                    meta="meta"
//                                    track></custom-metadata-edit>
//                 </form-el>
//             </custom-metadata-collection>`,
//         bindings: { sendProducts: '<', order: '<', default: '<' },
//         controller: function($http, $scope, $rootScope, GlobalProductReservation, $q, syncHttp, $filter, Product, $translate,UIMessages) {
//             const $ctrl = this
//
//             $ctrl.busy = false
//
//             $ctrl.reserve = reserve;
//
//             $ctrl.release = release;
//
//             $ctrl.currentChecked = null;
//             $ctrl.currentProductGuid = null;
//             $ctrl.isInit = true;
//
//             $scope.$watch('$ctrl.currentProductGuid', updateChecked);
//
//             $ctrl.$postLink = () => {
//                 $ctrl.shop = $ctrl.order.shop;
//                 _.forEach($ctrl.order.globalProductReservations, function(g) {
//                     _.forEach($ctrl.sendProducts, function(sp) {
//                         if(g.product_guid === sp.guid){
//                             $ctrl.currentChecked = sp;
//                             $ctrl.currentProductGuid = sp.guid;
//                             $ctrl.isInit = false;
//                         }
//                     });
//                 });
//                 if(!$ctrl.currentChecked){
//                     $ctrl.currentProductGuid = $ctrl.default;
//                     $ctrl.currentChecked = Product.cached[$ctrl.currentProductGuid];
//                     $ctrl.reserve($ctrl.currentProductGuid);
//                 }
//             };
//
//
//
//             function updateChecked(newVal, oldVal){
//                 if(!$ctrl.isInit){
//                     $ctrl.release($ctrl.currentChecked.guid);
//                     $ctrl.reserve($ctrl.currentProductGuid);
//                 }
//             }
//
//
//             /**
//              * Reserve a global product
//              */
//             function reserve(productId) {
//                 return
//                 $ctrl.busy = true;
//                 return $http.get(`/${$ctrl.shop.guid}/reserve/product/${productId}`)
//                     .then((response) => {
//                         response.data = Object.assign(response.data, {
//                             product_guid: productId
//                         })
//
//                         let productReservation =  new GlobalProductReservation(response.data, $ctrl.shop)
//                         $ctrl.order.globalProductReservations.push(productReservation)
//                         $rootScope.$broadcast('GlobalProductReservationsChanged')
//                         $ctrl.displayCount++;
//                         $ctrl.totalCount++;
//                         $ctrl.currentChecked = Product.cached[productId];
//                         // updateMetaData()
//                         // ticket.constructor.broadcastAPIMessage(response)
//                         $ctrl.busy = false;
//                         $ctrl.isInit = false;
//
//                         return response.data.reservation
//                     }, error => {
//                         $ctrl.busy = false;
//                         $ctrl.isInit = false;
//                         UIMessages.push((error.data.error_description ? error.data.error_description : 'Error'));
//                         return;
//                     })
//             }
//
//             /**
//              * Release a global product reservation
//              * @param {boolean} sync whether or not to call this function synchronized
//              */
//             function release(productId, sync) {
//                 let globalProductReservationsForProduct = $ctrl.order.globalProductReservations.filter(g => g.product_guid === productId)
//
//                 $ctrl.busy = true
//
//                 if (globalProductReservationsForProduct.length > 0) {
//                     const guid = globalProductReservationsForProduct[[globalProductReservationsForProduct.length-1]].reservation
//                     const url = `/${$ctrl.shop.guid}/reserve/product/${productId}/${guid}`
//                     let promise = null
//
//                     if (sync) {
//                         promise = syncHttp('DELETE', url)
//                     }
//                     else {
//                         promise = $http.delete(url)
//                     }
//
//                     return promise
//                         .then(response => {
//                             $ctrl.order.globalProductReservations = $ctrl.order.globalProductReservations.filter(g => g.reservation !== guid)
//                             $rootScope.$broadcast('GlobalProductReservationsChanged')
//                             $ctrl.busy = false;
//                             $ctrl.displayCount--;
//                             $ctrl.totalCount--;
//                             // updateMetaData()
//                             return response.data
//                         }, error => {
//                             $ctrl.busy = false
//
//                             return $q.reject(error)
//                         })
//                 }
//                 else {
//                     return new Promise((resolve, reject) => {
//                         $ctrl.busy = false
//
//                         reject('No global products reserved with this product guid.')
//                     })
//                 }
//             }
//
//             $ctrl.getLabel = function(meta, productId, index) {
//                 let product = Product.cached[productId]
//                 let productName = product.name;
//                 let label = `${index + 1}. ${productName}: `;
//                 if(meta.type === 'boolean')
//                     return label;
//                 label += $translate.instant(meta.translateName);
//                 return label;
//             };
//         }
//     }).name;
